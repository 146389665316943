import { PBRMaterial, Color3 } from '@babylonjs/core'

import { State } from '@orangelv/bjs-renderer'

import assert from '../../../platform/assert'

const onAfterMaterialsUpdated = (webId?: string) => (state: State) => {
  const materialIdsForLeather = [
    'bigLacePinky_mat',
    'bigLace_mat',
    'binding_mat',
    'finger1_mat',
    'finger2_mat',
    'finger3_mat',
    'finger4_mat',
    'finger5_mat',
    'finger6_mat',
    'finger7_mat',
    'finger8_mat',
    'finger9_mat',
    'finger10_mat',
    'fingerLaces_mat',
    'shellBack_fingerTips_mat',
    'heelLaces_mat',
    'shellBackLaces_mat',
    'iFingerPad_mat',
    'mFingerPad_mat',
    'iFingerHood_mat',
    'mFingerHood_mat',
    'lining_mat',
    'metallic_mat',
    'backPalm_mat',
    'shellBack_mat',
    'shellBack_OvalR_mat',
    'trim_mat',
    'trim_narrowFit_mat',
    'weltingBack_mat',
    'weltingPalm_mat',
    'weltingBack_decoMesh_mat',
    'weltingPalm_decoMesh_mat',
    'weltingBack_proMesh_mat',
    ...(webId ?
      [
        `${webId}_proMesh_OvalR_mat`,
        `${webId}_proMesh_mat`,
        `${webId}_back_OvalR_mat`,
        `${webId}_back_mat`,
        `${webId}_back_left_mat`,
        `${webId}_back_decoMesh_mat`,
        `${webId}_base_mat`,
        `${webId}_fingerLaces_mat`,
        `${webId}_heelLaces_mat`,
        `${webId}_palm_mat`,
        `${webId}_trim_mat`,
        `${webId}_weltingPalm_mat`,
        `${webId}_trim_narrowFit_mat`,
      ]
    : []),
  ]

  const materialIdsForSuede = [
    'finger1Edge_mat',
    'finger2Edge_mat',
    'finger3Edge_mat',
    'finger4Edge_mat',
    'finger5Edge_mat',
    'finger6Edge_mat',
    'finger7Edge_mat',
    'finger8Edge_mat',
    'finger9Edge_mat',
    'finger10Edge_mat',
    'fingerLacesInside_mat',
    'heelLacesInside_mat',
    'backPalmEdge_mat',
    'shellPalmEdge_mat',
    'shellBackEdge_mat',
    'shellBackEdge_OvalR_mat',
    'shellBackLacesEdge_mat',
    'trimEdge_mat',
    'trimEdge_narrowFit_mat',
    'liningEdge_mat',
    'iFingerHoodEdge_mat',
    'mFingerHoodEdge_mat',
    'iFingerPadEdge_mat',
    'iFingerPadWithHoodEdge_mat',
    'mFingerPadEdge_mat',
    'bigLaceEdge_mat',
    'bigLacePinkyEdge_mat',
    'shellBack_decoMesh_OvalR_backEdge_mat',
    'shellBackEdge_fingerTips_mat',
    'shellBackEdge_mat',
    `shellBack_proMesh_backEdge_mat`,
    ...(webId ?
      [
        `${webId}_backEdge_OvalR_mat`,
        `${webId}_decoMesh_OvalR_backEdge_mat`,
        `${webId}_baseEdge_mat`,
        `${webId}_checker_mat`,
        `${webId}_backEdge_mat`,
        `${webId}_backEdge_left_mat`,
        `${webId}_backEdge_decoMesh_mat`,
        `${webId}_backEdge_proMesh_mat`,
        `${webId}_backEdge_proMesh_OvalR_mat`,
        `${webId}_fingerLacesInside_mat`,
        `${webId}_heelLacesInside_mat`,
        `${webId}_palmEdge_mat`,
        `${webId}_trimEdge_mat`,
        `${webId}_trimEdge_narrowFit_mat`,
      ]
    : []),
  ]
  const materialIdsForShellBackMaterials = [
    `shellBack_speedShellCamel_mat`,
    `shellBack_carbonFiberMesh_mat`,
    `shellBack_carbonFiberBlack_mat`,
    `shellBack_speedShellMesh_mat`,
    `shellBack_zebraKnitMesh_mat`,
    `shellBack_blackCrocMesh_mat`,
    `shellBack_blackMesh_mat`,
    `shellBack_brownMesh_mat`,
    `shellBack_camoMesh_mat`,
    ...(webId ?
      [`${webId}_back_decoMesh_mat`, `${webId}_backEdge_decoMesh_mat`]
    : []),
  ]

  const materialIdsForShellBackDesignMaterials = [
    `shellBack_speedShellCamel_mat`,
    `shellBack_carbonFiberMesh_mat`,
    `shellBack_carbonFiberBlack_mat`,
    `shellBack_speedShellMesh_mat`,
    `shellBack_zebraKnitMesh_mat`,
    `shellBack_blackCrocMesh_mat`,
    `shellBack_blackMesh_mat`,
    `shellBack_brownMesh_mat`,
    `shellBack_camoMesh_mat`,
  ]

  const materialIdsForStitching = [
    `shellBack_stitching_mat`,
    `shellBack_proMesh_stitching_mat`,
    `shellBack_decoMesh_stitching_mat`,
    `shellBack_decoMesh_extra_stitching_mat`,
    `backPalm_stitching_mat`,
    `shellPalm_stitching_mat`,
    `stitching_mat`,
    `lining_stitching_mat`,
    `trim_stitching_mat`,
    `iFingerPad_stitching_mat`,
    `iFingerHood_stitching_mat`,
    `mFingerPad_stitching_mat`,
    `mFingerHood_stitching_mat`,
    `embroideryOvalR_stitching_mat`,
    `finger1_stitching_mat`,
    `finger2_stitching_mat`,
    `finger3_stitching_mat`,
    `finger4_stitching_mat`,
    `finger5_stitching_mat`,
    `finger6_stitching_mat`,
    `finger7_stitching_mat`,
    `finger8_stitching_mat`,
    `finger9_stitching_mat`,
    `finger10_stitching_mat`,
    `back_left_stitching_mat`,
    `back_stitching_mat`,
    ...(webId ?
      [
        `${webId}_stitching_mat`,
        `${webId}_backEdge_decoMesh_mat`,
        `${webId}_decoMesh_stitching_mat`,
      ]
    : []),
  ]
  const materialIdsForEmbroidery = [
    `patch_mat`,
    `embroideryOvalR_mat`,
    `embroideryGoldGlove_mat`,
    `embroideryLogo_mat`,
    `embroideryNumbers_mat`,
    `embroideryText_mat`,
    `patch_MLB`,
    ...(webId ? [`${webId}_embroideryOvalR_mat`] : []),
  ]

  const materialIdsForStamping = [
    `stamping_Leather_mat`,
    `stamping_RawlingsLogo_mat`,
  ]

  // TODO: Replace the list of material IDs with metadata tags from Blender.
  // It should be something like `'leatherMaterial' in material.metadata`.
  const isLeatherMaterial = (material: PBRMaterial) =>
    materialIdsForLeather.includes(material.id)
  const isSuedeMaterial = (material: PBRMaterial) =>
    materialIdsForSuede.includes(material.id)
  const isStitchingMaterial = (material: PBRMaterial) =>
    materialIdsForStitching.includes(material.id)
  const isEmbroideryMaterial = (material: PBRMaterial) =>
    materialIdsForEmbroidery.includes(material.id)
  const isShellBackMaterial = (material: PBRMaterial) =>
    materialIdsForShellBackMaterials.includes(material.id)
  const isStampingMaterial = (material: PBRMaterial) =>
    materialIdsForStamping.includes(material.id)

  const { scene } = state

  assert(scene)

  for (const material of scene.materials as PBRMaterial[]) {
    const isLeather = isLeatherMaterial(material)
    const isSuede = isSuedeMaterial(material)
    const isStitching = isStitchingMaterial(material)
    const isEmbroidery = isEmbroideryMaterial(material)
    const isShellBack = isShellBackMaterial(material)
    const isStamping = isStampingMaterial(material)

    if (
      !isLeather &&
      !isSuede &&
      !isStitching &&
      !isEmbroidery &&
      !isStamping
    ) {
      continue
    }

    if (isStamping) {
      material.useAlphaFromAlbedoTexture = true
    }

    const bumpTexture = material.bumpTexture

    if (bumpTexture) {
      bumpTexture.level = 3
    }

    if (isLeather && material.ambientTexture) {
      const ambientTexture = material.ambientTexture.clone()
      material.roughness = 1.5 // Leather-specific roughness

      // Sheen settings for leather
      material.sheen.isEnabled = true
      material.sheen.intensity = 0.1
      material.sheen.roughness = 1
      material.sheen.texture = ambientTexture

      // Clear coat settings for leather
      material.clearCoat.isEnabled = true
      material.clearCoat.intensity = 0.025
      material.clearCoat.indexOfRefraction = 2
      material.clearCoat.roughness = 0.4

      // Lightmap settings for leather
      material.lightmapTexture = ambientTexture
      material.useLightmapAsShadowmap = true

      // Remove the original ambient texture from the material
      material.ambientTexture = null
    }
    if (isSuede) {
      if (
        (material.name.includes('Inside') ||
          material.name.includes('checker')) &&
        !material.name.includes('Laces')
      ) {
        material.albedoColor = new Color3(0.658, 0.328, 0.102)
      }
      if (material.name.includes('Edge') && !material.name.includes('Laces')) {
        material.albedoColor = new Color3(0.45, 0.5, 0.55)
      }
    }

    if (isSuede && material.ambientTexture) {
      const ambientTexture = material.ambientTexture
      material.roughness = 1 // Suede-specific roughness
      material.lightmapTexture = ambientTexture
      material.useLightmapAsShadowmap = true
      material.ambientTexture = null // Optionally clear the ambient texture
    }

    if (isStitching && material.ambientTexture) {
      const ambientTexture = material.ambientTexture.clone()
      material.roughness = 1 // Stitching-specific roughness

      // Sheen settings for stitching
      material.sheen.isEnabled = true
      material.sheen.intensity = 0.1
      material.sheen.roughness = 1
      material.sheen.texture = ambientTexture

      // Clear coat settings for stitching
      material.clearCoat.isEnabled = true
      material.clearCoat.intensity = 0.025
      material.clearCoat.indexOfRefraction = 2
      material.clearCoat.roughness = 0.4

      // Lightmap settings for stitching
      material.lightmapTexture = ambientTexture
      material.useLightmapAsShadowmap = true

      // Remove the original ambient texture from the material
      material.ambientTexture = null
    }

    if (isEmbroidery && material.ambientTexture) {
      const ambientTexture = material.ambientTexture.clone()
      material.roughness = 0.6 // embroidery-specific roughness

      // Sheen settings for embroidery
      material.sheen.isEnabled = true
      material.sheen.intensity = 0.1
      material.sheen.roughness = 1
      material.sheen.texture = ambientTexture

      // Clear coat settings for embroidery
      material.clearCoat.isEnabled = true
      material.clearCoat.intensity = 0.025
      material.clearCoat.indexOfRefraction = 2
      material.clearCoat.roughness = 0.4

      // Lightmap settings for embroidery
      material.lightmapTexture = ambientTexture
      material.useLightmapAsShadowmap = true

      // Remove the original ambient texture from the material
      material.ambientTexture = null
    }

    if (isShellBack && material.ambientTexture) {
      const ambientTexture = material.ambientTexture.clone()

      // Lightmap.
      material.lightmapTexture = ambientTexture
      material.useLightmapAsShadowmap = true

      material.ambientTexture = null
    }
  }

  // Design Lightmaps.
  const designMeshes = scene.meshes.filter(
    (mesh) =>
      mesh.id === 'shellBack_proMesh' ||
      mesh.id === 'shellBack_decoMesh' ||
      mesh.id === 'shellBack_decoMesh_extra_mesh' ||
      mesh.id === 'shellBack_decoMesh_OvalR' ||
      mesh.id === 'shellBack_decoMesh_OvalR_extra_mesh',
  )
  const designSourceMaterials = {
    shellBack_proMesh: 'shellBack_proMesh_mat',
    shellBack_decoMesh: 'shellBack_decoMesh_mat',
    shellBack_decoMesh_extra_mesh: 'shellBack_decoMesh_mat',
    shellBack_decoMesh_OvalR: 'shellBack_decoMesh_OvalR_mat',
    shellBack_decoMesh_OvalR_extra_mesh: 'shellBack_decoMesh_OvalR_mat',
  }
  let sourceMat
  console.log('DesignMeshes Found:  ' + designMeshes.length)

  if (designMeshes.length > 0) {
    designMeshes.forEach((mesh) => {
      console.log(mesh.id + '   ' + mesh.isEnabled())
      if (mesh.isEnabled() === true) {
        sourceMat = scene.materials.find(
          (mat) => mat.id === designSourceMaterials[mesh.id],
        )
      }
    })
    if (sourceMat) {
      console.log(sourceMat.id)

      const designMaterials = scene.materials.filter((mat) =>
        materialIdsForShellBackDesignMaterials.includes(mat.id),
      )

      designMaterials.forEach((mat) => {
        const thisMat = mat as PBRMaterial
        thisMat.lightmapTexture = sourceMat.ambientTexture
        thisMat.useLightmapAsShadowmap = true
        console.log('update')
      })
    }
  }
}

export default onAfterMaterialsUpdated
