import '@babylonjs/core/Debug/debugLayer';
import '@babylonjs/inspector';
import '@babylonjs/loaders/glTF';

import { type ReactElement, useRef, useState } from 'react';

import getFabricCanvasIds from './materials/get-fabric-canvas-ids.js';
import type { Props, State } from './types.js';
import useRenderer from './use-renderer.js';

export const BjsRenderer = (props: Props): ReactElement => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const coverCanvasRef = useRef<HTMLCanvasElement>(null);
  const fabricCanvasesRef = useRef<Record<string, HTMLCanvasElement>>({});

  const [isCoverCanvasActive, setIsCoverCanvasActive] = useState(false);

  const stateRef = useRef<State>({
    isDigesting: false,
    isReady: false,
    isGeneratingPreviews: false,
    canvasRef,
    coverCanvasRef,
    fabricCanvasesRef,
    setIsCoverCanvasActive,
    props,
    models: {},
  });

  useRenderer(stateRef, props);

  return (
    <>
      <canvas
        ref={canvasRef}
        style={{
          display: 'block', // Better default than inline
          width: '100%',
          height: '100%',
          outline: 0,
          visibility: isCoverCanvasActive ? 'hidden' : 'visible',
          touchAction: 'none',
        }}
      />

      <canvas
        ref={coverCanvasRef}
        style={{
          width: '100%',
          height: '100%',
          outline: 0,
          position: 'absolute',
          top: 0,
          left: 0,
          display: isCoverCanvasActive ? 'block' : 'none',
        }}
      />

      {getFabricCanvasIds(stateRef, props)().map((canvasId) => (
        <div key={canvasId}>
          <canvas
            ref={(canvas): void => {
              if (!canvas) {
                return;
              }

              fabricCanvasesRef.current[canvasId] = canvas;
            }}
            style={{
              // Unfortunately we cannot set display to "none", it must be visually hidden.
              visibility: 'hidden',
            }}
          />
        </div>
      ))}
    </>
  );
};
